/**
 * Created by itamar on 11-Apr-16.
 */
import { LoginType } from '../components/modal-login/modal-login.model';
import { Injectable } from '@angular/core';
import AuthResponse = facebook.AuthResponse;

@Injectable({ providedIn: 'root' })
export class FacebookService {
    constructor() {}

    checkLoginState(callback: (accessToken: string, type: LoginType) => void) {
        window.FB.login(
            (response: any) => {
                if (response.authResponse) {
                    const fbToken = window.FB.getAuthResponse().accessToken;
                    if (response.status === 'connected') {
                        callback(fbToken, 'fb');
                    }
                } else {
                    console.log(
                        'User cancelled login or did not fully authorize.'
                    );
                }
            },
            { scope: 'public_profile,email,user_birthday,user_relationships' }
        );
    }

    watchLoginChange() {
        window.FB.Event.subscribe('auth.authResponseChange', (res: any) => {
            if (res.status === 'connected') {
                /*
                 The user is already logged,
                 is possible retrieve his personal info
                 */
                return this.getUserInfo();
                /*
                 This is also the point where you should create a
                 session for the current user.
                 For this purpose you can use the data inside the
                 res.authResponse object.
                 */
            } else {
                /*
                 The user is not logged to the app, or into Facebook:
                 destroy the session on the server.
                 */
            }
        });
    }

    getUserInfo() {
        window.FB.api('/me', (user: any) => {
            // do something with user
        });
    }

    logout() {
        window.FB?.getLoginStatus((response: AuthResponse) => {
            if (response.status === 'connected') {
                window.FB.logout();
            }
        });
    }

    share(link: string, shareText?: string) {
        window.FB.ui(
            {
                method: 'share',
                href: link,
                quote: shareText
                    ? shareText
                    : 'Download WishTrip today as the ultimate travel companion http://go.wishtrip.com/enjoy'
            },
            (response: any) => {
                console.log('FB share response', response);
            }
        );
    }
}
